<template>
  <div
    class="csn-lsm"
    :class="{ 'csn-lsm-displayed': SHOWS_PAY_AND_PLAY_SIDE_MENU }"
  >
    <div class="csn-lsm-header">
      <button @click="handleArrowClick" class="csn-lsm-arrow-back">
        <SlideArrow />
      </button>
      <NavbarLogoButton @click.native="handleCloseClick" />
      <button @click="handleCloseClick" class="csn-notification-menu-close-btn">
        <CloseIcon />
      </button>
    </div>

    <PayAndPlay />
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import {
  PAY_AND_PLAY_SIDE_MENU,
  TOGGLE_PAY_AND_PLAY_SIDE_MENU,
  SHOWS_PAY_AND_PLAY_SIDE_MENU,
  TOGGLE_MAIN_MENU,
  Module,
  RouteName,
  Digit,
} from '@/constants'
import { navigateTo, requestTimeout, cancelTimeout } from '@/helpers'

export default {
  name: PAY_AND_PLAY_SIDE_MENU,
  components: {
    CloseIcon: () => import('@/components/svg/CloseIcon'),
    SlideArrow: () => import('@/components/svg/SlideArrow'),
    NavbarLogoButton: () =>
      import('@/App/components/Navbar/components/NavbarLogoButton'),
    PayAndPlay: () => import('@/modals/PayAndPlay'),
  },
  data: () => ({
    openNextSideMenu: null,
  }),
  computed: {
    ...mapState(Module.MAIN, { SHOWS_PAY_AND_PLAY_SIDE_MENU }),
  },
  methods: {
    ...mapMutations(Module.MAIN, [
      TOGGLE_PAY_AND_PLAY_SIDE_MENU,
      TOGGLE_MAIN_MENU,
    ]),
    handleArrowClick() {
      this.TOGGLE_PAY_AND_PLAY_SIDE_MENU(false)

      this.$nextTick(() => {
        this.openNextSideMenu = requestTimeout(() => {
          cancelTimeout(this.openNextSideMenu)
          this.openNextSideMenu = null
          this.TOGGLE_MAIN_MENU()
        }, Digit.TWO_HUNDRED)
      })
    },
    handleCloseClick() {
      this.TOGGLE_PAY_AND_PLAY_SIDE_MENU(false)
      navigateTo({ name: RouteName.HOME })
    },
  },
}
</script>
